import styled from 'styled-components';

interface SidebarProps {
  active: boolean;
  expand: boolean;
  fixed: boolean;
  notDeskTop?: boolean;
  mobile: boolean;
}

export const SideMenu = styled.nav<SidebarProps>`
  grid-area: nav;
  position: fixed;
  top: 0;
  z-index: 899;
  height: 100vh;
  // height: calc(100vh - 4rem);
  background: ${props => props.theme.colors.sidebar};
  display: flex;
  flex-direction: column;
  width: ${props => (props.expand ? `218px` : `70px`)};
  transition: all 0.4s ease-in-out;

  .containerPopper > div {
    background-color: ${props =>
      props.theme.title === 'dark' ? '#262639' : '#FFF'} !important;
    box-shadow: 0px 4px 10px 0px #00000080 !important;
  }

  .containerTitle {
    background: #212e48;
    width: ${props => (props.expand ? `196px` : `45px`)};
    height: 45px;
    border-radius: 5px;
    justify-content: ${props => (props.expand ? `left` : `center`)};
    display: flex !important;

    .MuiButton-label {
      p {
        display: flex !important;
      }
    }
  }

  .TitleLanguage {
    width: ${props => (props.expand ? `196px` : `0px`)};
    background-color: ${props =>
      props.theme.title === 'dark' ? '#262639' : '#FFF'} !important;

    li {
      font-size: 12px !important;
      font-weight: 400 !important;
      width: 12rem !important;
      font-family: Poppins;
      line-height: 18px !important;
      color: ${props =>
        props.theme.title === 'dark' ? '#B5B5C3' : '#B5B5C3'} !important;
      width: 100% !important;

      &:hover {
        background-color: ${props =>
          props.theme.title === 'dark' ? '#212E48' : '#ECF8FF'} !important;
        color: #187de4 !important;
      }

      animation: ${props =>
        props.expand
          ? 'outOpacityArrow 0.5s ease-in-out both'
          : 'opacityArrow 0.5s ease-in-out both'};
      animation-name: slidein;

      @keyframes slidein {
        75% {
          font-size: 300%;
          margin-left: 25%;
          width: 150%;
        }
      }
    }

    img {
      margin-right: 10px;
    }
  }

  .titleMenuLanguage {
    font-size: 12px !important;
    color: #187de4 !important;
    text-transform: none !important;
    display: flex !important;

    img {
      margin-right: 10px !important;
    }

    /* bug fix */
    /* animation: ${props =>
      props.expand
        ? 'outOpacityArrow 0.5s ease-in-out both'
        : 'opacityArrow 0.5s ease-in-out both'};
    animation-name: slidein; */

    @keyframes slidein {
      75% {
        font-size: 300%;
        margin-left: 25%;
        width: 150%;
      }
    }
  }

  .version {
    color: #6c7293;
    font-size: 12px;
    padding-left: 10px;
  }

  .arrow-back {
    display: flex;
    border-radius: 50% !important;
    padding: 7px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    justify-content: end;
    z-index: 99;
    position: absolute;
    top: 25px;
    right: ${props => (props.expand ? `-10px` : `-15px`)};
    background: #212e48;
    cursor: pointer;
    transition: all 0.4s;
    animation-delay: 0.5s;
    animation: ${props =>
      props.fixed
        ? 'outOpacityArrow 0.2s ease-in-out both'
        : 'opacityArrow 0.2s ease-in-out both'};
    &:hover {
      background: #0095e8;
    }

    .arrow-back-span {
      display: flex;
      justify-content: end;

      /* background-color: ${props => props.theme.colors.primary}; */
      border-radius: 50%;
      width: fit-content;
      height: fit-content;

      .arrow-back-button {
        all: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 12px;
        transform: rotate(0deg);
        transition: all 0.3s ease;
        color: #b0b0b0;
        &:hover {
          color: #fff;
        }
        svg {
          font-size: 12px;
        }

        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
  }

  .account {
    background-color: #222222;
    display: flex;
    align-items: center;
    animation: ${props =>
      props.expand
        ? 'opacityAccount 0.2s ease-in-out both'
        : 'outOpacityAccount 0.2s ease-in-out both'};

    @keyframes opacityAccount {
      0% {
        opacity: 0;
      }

      60% {
        flex-direction: initial;
        justify-content: space-between;
        opacity: 0;
      }

      100% {
        width: initial;
        padding: 1rem 1.5rem;
        flex-direction: initial;
        justify-content: space-between;
        opacity: 1;
      }
    }

    @keyframes outOpacityAccount {
      0% {
        flex-direction: initial;
        opacity: 0;
      }

      60% {
        display: flex;
        flex-direction: column-reverse;
        opacity: 0;
      }

      100% {
        opacity: 1;
        justify-content: center;
        flex-direction: column-reverse;
        width: 100%;
        padding: 1rem 0;
      }
    }

    .profile {
      display: flex;
      align-items: center;

      .profileContent {
        display: block;
        margin-left: 0.8rem;

        .logedProfile {
          font-weight: 400;
          font-size: 14px;
          color: ${props => props.theme.colors.text};
        }

        .clientProfile {
          font-weight: 400;
          font-size: 12px;
          color: #858585;
        }
      }
    }

    .options {
      margin-bottom: ${props => (props.expand ? '0' : '1rem')};
      #btn {
        width: 35px;
        height: 35px;
        padding: 7px;
        background: #303030;
      }
    }
  }

  &.active {
    width: 270px;

    .content {
      .top {
        .container {
          justify-content: space-between;
          .buttonArrowLeft {
            transform: rotate(0deg);
          }
          .logo {
            display: initial;
          }
        }
      }

      .links-list {
        transition: all 0.3s;
        margin-top: 32px;

        .title {
          .container {
            .left {
              .icon {
                align-items: center;
                justify-content: center;
              }
              .text {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .content {
    padding: 0 0.5rem;
    height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
      width: 5px;
    }

    &:hover::-webkit-scrollbar {
      display: ${props => (props.notDeskTop ? 'block' : 'none')};
    }

    .top {
      display: inline-block;
      width: 100%;
      transition: all 0.3s ease;
      margin-left: 0.5rem;
      padding: ${props => (props.expand ? '24px 0' : '24px')};

      .container {
        width: 100% !important;
        display: flex;
        justify-content: space-between;
        flex-direction: ${props => (props.expand ? 'initial' : 'column')};
        align-items: center;
        text-align: center;

        /* .logo {
          width: 100%;
        } */

        @keyframes flexIn {
          0% {
            opacity: 0;
          }

          60% {
            display: flex;
            flex-direction: column;
            opacity: 0;
          }

          100% {
            display: flex;
            flex-direction: column;
            opacity: 1;
          }
        }

        @keyframes flexOut {
          0% {
            opacity: 0;
          }

          60% {
            display: flex;
            flex-direction: initial;
            opacity: 0;
          }

          100% {
            display: flex;
            flex-direction: initial;
            opacity: 1;
          }
        }

        .buttonArrowLeft {
          transform: rotate(180deg);
          border-radius: 50%;
          svg {
            fill: #fff !important;
            transition: none !important;
          }
        }

        @keyframes opacityArrow {
          0% {
            opacity: 1;
            display: initial;
          }

          40% {
            opacity: 0;
            display: none;
            z-index: -5;
            position: fixed;
          }

          60% {
            opacity: 0;
          }

          100% {
            opacity: 1;
            display: initial;
          }
        }

        @keyframes outOpacityArrow {
          0% {
            opacity: 0;
            display: none;
            z-index: -5;
            position: fixed;
          }
          60% {
            opacity: 0;
          }
          100% {
            opacity: 1;
            display: initial;
          }
        }

        .arrow {
          width: 24px;
          height: 24px;
          background: transparent;
          cursor: pointer;

          img {
            max-width: 100%;
          }
        }
      }
    }

    .name {
      display: none;
    }

    .links-list {
      margin-top: 1rem;

      .title {
        .container {
          .left {
            .icon {
              align-items: center;
              justify-content: center;
            }
          }
          /* TODO: .text */
          .arrow {
            display: initial;
          }
        }
      }
    }
  }

  @media screen and (max-width: 981px) {
    position: absolute;
    left: ${props => (props.active ? `0px` : `-270px`)};
    top: 0;
    width: 270px;
    height: 100%;
    z-index: 1002;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    .TitleLanguage {
      width: 250px;
      max-width: 100%;
      height: 100%;
    }

    .containerTitle {
      width: 250px;
      max-width: 100%;
      height: 80%;
    }

    .arrow-back {
      right: -20px !important;
    }

    .content {
      .top {
        background: ${props => props.theme.colors.sidebar};
        justify-content: center;
        align-items: center;
        .container {
          justify-content: center !important;
          .buttonArrowLeft {
            display: none;
          }
        }
      }

      .links-list {
        margin-top: 1rem;
      }

      .name {
        width: 100%;
        margin: 1rem 0;
        background: ${props => props.theme.colors.sidebarSelected};
        border-radius: 4px;
        padding: 10px;
        margin-top: 1.5rem;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .profile {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .user {
            display: flex;
            align-items: center;

            p {
              color: #ddd !important;
              font-size: 12px;
              font-weight: normal !important;
            }
          }

          .moreBtn {
            border-radius: 50%;
          }
        }
        .collapse {
          display: flex;
          justify-content: flex-start;
          width: 100%;

          .MuiCollapse-wrapper {
            width: 100%;

            .logoutBtn {
              color: #ccc;
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 601px) {
    .containerTitle {
      position: relative;
      bottom: -10px;
    }

    .version {
      margin: 10px 5px !important;
    }

    @media screen and (max-width: 245px) {
      .containerTitle {
        position: relative;
        bottom: 9.375rem;
      }
    }
  }
`;
