import api from 'services/api';
import { DetailInventoryData } from 'utils/interfaces/Log';

export const searchBoxRequest = async (
  data: any,
  clientId: number,
  userId: number
) => {
  const formatedData = {
    CodigoCliente: clientId,
    User: userId,
    endereco: data.endereco || null,
    codigoDeposito: data.deposito || null,
    produto: data.produto || null,
    codigoPedido: data.pedido || null,
    codigoMarca: data.marca || null,
    descricaoPalete: data.palete || null,
    descricaoCaixa: data.caixa || null,
    CodigoCodigoNivel1: data.selectLevel || null,
    CodigoCodigoNivel2: data.selectLevel2 || null,
    CodigoCodigoNivel3: data.selectLevel3 || null,
    CodigoCodigoNivel4: data.selectLevel4 || null,
    CodigoCodigoNivel5: data.selectLevel5 || null,
    CodigoLoja: data.codigoLoja || null,
  };

  return api.patch('Relatorio/ArmazenagemEstoque', {
    ...formatedData,
  });
};

export const getAllOptions = async (clientId: number) => {
  const [deposits, brands, level1, branchs] = await Promise.all([
    api.get(`Common/Deposito?CodigoCliente=${clientId}`),
    api.get(`Common/Marca?CodigoCliente=${clientId}`),
    api.get(`Common/EstruturaMercadologica?CodigoCliente=${clientId}&Nivel=0`),
    api.get(`Recebimento/searchbranch?CodigoCliente=${clientId}`),
  ]);

  return [deposits, brands, level1, branchs];
};

export const getNigel2API = (clientId: number, codeNivel: string) =>
  api.get(
    `Common/EstruturaMercadologica?CodigoCliente=${clientId}&Nivel=1&CodigoPai=${codeNivel}`
  );

export const getNigel3API = (clientId: number, codeNivel: string) =>
  api.get(
    `Common/EstruturaMercadologica?CodigoCliente=${clientId}&Nivel=2&CodigoPai=${codeNivel}`
  );

export const getNigel4API = (clientId: number, codeNivel: string) =>
  api.get(
    `Common/EstruturaMercadologica?CodigoCliente=${clientId}&Nivel=3&CodigoPai=${codeNivel}`
  );

export const getNigel5API = (clientId: number, codeNivel: string) =>
  api.get(
    `Common/EstruturaMercadologica?CodigoCliente=${clientId}&Nivel=4&CodigoPai=${codeNivel}`
  );

export const detailsRequest = async (data: any) =>
  api.patch<DetailInventoryData[]>('Relatorio/ArmazenagemEstoqueDetalhe', {
    ...data,
  });
